import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  TextField,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  CssBaseline,
  AppBar,
  Typography,
} from "@material-ui/core";
import { useLocation, useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import {
  ExitToApp as ExitToAppIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import {
  fetchData,
  saveOrUpdateData,
  updateStatus,
  saveVerifyingData1,
  saveComment,
  saveCheckbox,
  getSADAndDrugaWartosc,
  updatePrzygotowane,
  getPrzygotowane,
  updateStatusDoWeryfikacji,
  getZweryfikowane,
  updatePrzygotowaneZweryfikowane,
  updateZweryfikowanePrzygotowaneDoWyslania,
  updateStatusDoPoprawy,
  updateStatusDoWyslania,
} from "./PrzywozFunctions";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  tableContainer: {
    marginTop: theme.spacing(3),
  },
  tableHeadCell: {
    fontWeight: "bold",
  },
  checkCell: {
    width: "40%",
    wordWrap: "break-word",
    whiteSpace: "normal",
    fontWeight: "bold",
  },
  inputField: {
    width: "100%",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
}));

const PrzywozZwykly = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    nrRef,
    osobaPrzygotowujaca,
    osobaWeryfikujaca,
    status,
    rodzajOdprawy,
  } = location.state || {};

  const { id } = useParams();
  const token = localStorage.getItem("token");
  let username;
  let role;

  if (token) {
    const decoded = jwtDecode(token);
    username = decoded.username;
    role = decoded.role;
    console.log("Username and role");
    console.log(username, role);
  }

  const [data, setData] = useState({
    creationDate: "",
    lastUpdateDate: "",
    textFields: {
      9: "",
      10: "",
      16: "",
    },
    selectFields: {
      12: "",
      24: "",
      25: "",
      28: "",
      33: "",
      34: "",
    },
    checkboxesPreparation: {
      1: { verifying: false },
      2: { verifying: false },
      3: { verifying: false },
      4: { verifying: false },
      5: { verifying: false },
      6: { verifying: false },
      7: { verifying: false },
      8: { verifying: false },
      11: { verifying: false },
      13: { verifying: false },
      14: { verifying: false },
      15: { verifying: false },
      17: { verifying: false },
      18: { verifying: false },
      19: { verifying: false },
      20: { verifying: false },
      21: { verifying: false },
      22: { verifying: false },
      23: { verifying: false },
      26: { verifying: false },
      27: { verifying: false },
      29: { verifying: false },
      30: { verifying: false },
      31: { verifying: false },
      32: { verifying: false },
    },
    checkboxesVerification: {
      1: { good: false, bad: false },
      2: { good: false, bad: false },
      3: { good: false, bad: false },
      4: { good: false, bad: false },
      5: { good: false, bad: false },
      6: { good: false, bad: false },
      7: { good: false, bad: false },
      8: { good: false, bad: false },
      9: { good: false, bad: false },
      10: { good: false, bad: false },
      11: { good: false, bad: false },
      12: { good: false, bad: false },
      13: { good: false, bad: false },
      14: { good: false, bad: false },
      15: { good: false, bad: false },
      16: { good: false, bad: false },
      17: { good: false, bad: false },
      18: { good: false, bad: false },
      19: { good: false, bad: false },
      20: { good: false, bad: false },
      21: { good: false, bad: false },
      22: { good: false, bad: false },
      23: { good: false, bad: false },
      24: { good: false, bad: false },
      25: { good: false, bad: false },
      26: { good: false, bad: false },
      27: { good: false, bad: false },
      28: { good: false, bad: false },
      29: { good: false, bad: false },
      30: { good: false, bad: false },
      31: { good: false, bad: false },
      32: { good: false, bad: false },
      33: { good: false, bad: false },
      34: { good: false, bad: false },
    },
    checkboxesInternalControl: {
      1: { internalControl: false },
      2: { internalControl: false },
      3: { internalControl: false },
      4: { internalControl: false },
      5: { internalControl: false },
      6: { internalControl: false },
      7: { internalControl: false },
      8: { internalControl: false },
      9: { internalControl: false },
      10: { internalControl: false },
      11: { internalControl: false },
      12: { internalControl: false },
      13: { internalControl: false },
      14: { internalControl: false },
      15: { internalControl: false },
      16: { internalControl: false },
      17: { internalControl: false },
      18: { internalControl: false },
      19: { internalControl: false },
      20: { internalControl: false },
      21: { internalControl: false },
      22: { internalControl: false },
      23: { internalControl: false },
      24: { internalControl: false },
      25: { internalControl: false },
      26: { internalControl: false },
      27: { internalControl: false },
      28: { internalControl: false },
      29: { internalControl: false },
      30: { internalControl: false },
      31: { internalControl: false },
      32: { internalControl: false },
      33: { internalControl: false },
      34: { internalControl: false },
    },
    nrRef: "",
    osobaPrzygotowujaca: "",
    osobaWeryfikujaca: "",
    comment: "", // Dodane pole komentarza
  });

  const [loading, setLoading] = useState(true);
  const [isAllDataSaved, setIsAllDataSaved] = useState(false);
  const [isAllInternalControleSaved, setIsAllInternalControleSaved] =
    useState(false);
  const [allVeryfyingDataFilled, setAllVeryfyingDataFilled] = useState(false);
  const [allVerificationSet, setAllVerificationSet] = useState(false); // Nowy stan dla śledzenia, czy wszystkie checkboxy w kolumnie "Osoba weryfikująca" są ustawione

  const selectOptions = {
    12: [
      "CFR",
      "CIF",
      "CIP",
      "CPT",
      "DAP",
      "DDP",
      "DPU",
      "EXW",
      "FAS",
      "FCA",
      "FOB",
    ],
    24: ["TAK", "NIE"],
    25: ["POPRAWNY", "NIE DOTYCZY"],
    28: ["POPRAWNY", "NIE DOTYCZY"],
    33: ["TAK", "NIE"],
    34: ["PRZELEW", "ODROCZENIE", "ODROCZENIE + GRN", "GRN", "ZWOLNIENIE"],
  };

  const allowedUsers = [
    "aszkutnik",
    "adobrowolska",
    "Maciej",
    "plomza",
    "dczernikowska",
  ];

  const verifyAndSubmit = async (data, status, id, username) => {
    if (status === "Do weryfikacji" || status === "Do wysłania") {
      return;
    }

    const requiredTextFields = [9, 10, 16];
    const requiredSelectFields = [12, 24, 25, 28, 33, 34];
    const requiredCheckboxes = [
      1, 2, 3, 4, 5, 6, 7, 8, 11, 13, 14, 15, 17, 18, 19, 20, 21, 22, 23, 26,
      27, 29, 30, 31, 32,
    ];

    const allTextFieldsFilled = requiredTextFields.every(
      (fieldId) => data.textFields[fieldId]
    );
    const allSelectFieldsFilled = requiredSelectFields.every(
      (fieldId) => data.selectFields[fieldId]
    );
    const allCheckboxesChecked = requiredCheckboxes.every(
      (fieldId) =>
        data.checkboxesPreparation[fieldId] &&
        data.checkboxesPreparation[fieldId].verifying
    );

    const usernames = [
      "adobrowolska",
      "bbrus",
      "dczernikowska",
      "mwojtas",
      "pmunik",
      "pnowak",
      "plomza",
      "smazur",
    ];

    try {
      const { SAD, Plik } = await getSADAndDrugaWartosc(id);
      console.log(`SAD: ${SAD}, PLIK: ${Plik}`);
      const PrzygotowaneFromDB = await getPrzygotowane(id);
      const ZweryfikowaneFromDB = await getZweryfikowane(id);
      console.log(`Zweryfikowane from DB: ${ZweryfikowaneFromDB}`);
      console.log(`Przygotowane from DB: ${PrzygotowaneFromDB}`);

      if (status === "Do przygotowania") {
        if (usernames.includes(username)) {
          if (
            SAD !== undefined &&
            Plik !== undefined &&
            SAD !== "" &&
            Plik !== 0
          ) {
            if (
              allCheckboxesChecked &&
              allSelectFieldsFilled &&
              allTextFieldsFilled
            ) {
              console.log("AAAAAAAA");
              const response = await updateZweryfikowanePrzygotowaneDoWyslania(
                id,
                {
                  status: "Przygotowane",
                  Przygotowane: 1,
                  Zweryfikowane: 1,
                },
                "przywoz"
              );
              console.log(response.message);
              alert(response.message);
              setTimeout(() => {
                navigate("/user");
              }, 1500);
            }
          } else {
            if (
              (PrzygotowaneFromDB === 0 ||
                PrzygotowaneFromDB === undefined ||
                PrzygotowaneFromDB === null) &&
              (ZweryfikowaneFromDB === 0 ||
                ZweryfikowaneFromDB === undefined ||
                ZweryfikowaneFromDB === null)
            ) {
              if (
                allCheckboxesChecked &&
                allSelectFieldsFilled &&
                allTextFieldsFilled
              ) {
                const response = await updatePrzygotowaneZweryfikowane(
                  id,
                  {
                    Przygotowane: 1,
                    Zweryfikowane: 1,
                  },
                  "przywoz"
                );
                console.log(response.message);
                alert(response.message);
                setTimeout(() => {
                  navigate("/user");
                }, 1500);
              }
            }
          }
        } else {
          if (
            SAD !== undefined &&
            SAD !== "" &&
            Plik !== undefined &&
            Plik !== 0
          ) {
            if (
              PrzygotowaneFromDB === 0 ||
              PrzygotowaneFromDB === undefined ||
              PrzygotowaneFromDB === null
            ) {
              if (
                allCheckboxesChecked &&
                allSelectFieldsFilled &&
                allTextFieldsFilled
              ) {
                const response = await updateStatusDoWeryfikacji(
                  id,
                  {
                    status: "Do weryfikacji",
                    Przygotowane: 1,
                  },
                  "przywoz"
                );
                console.log(response.message);
                alert(response.message);
                setTimeout(() => {
                  navigate("/user");
                }, 1500);
              }
            }
          } else {
            if (
              PrzygotowaneFromDB === 0 ||
              PrzygotowaneFromDB === undefined ||
              PrzygotowaneFromDB === null
            ) {
              if (
                allCheckboxesChecked &&
                allSelectFieldsFilled &&
                allTextFieldsFilled
              ) {
                const response = await updatePrzygotowane(
                  id,
                  { Przygotowane: 1 },
                  "przywoz"
                );
                console.log(response);
                console.log(response.message);
                alert(response.message);
                setTimeout(() => {
                  navigate("/user");
                }, 3000);
              }
            }
          }
        }
      } else if (status === "Przygotowane") {
        if (usernames.includes(username)) {
          if (
            SAD !== undefined &&
            Plik !== undefined &&
            SAD !== "" &&
            Plik !== 0
          ) {
            if (
              allCheckboxesChecked &&
              allSelectFieldsFilled &&
              allTextFieldsFilled
            ) {
              console.log("AAAAAAAA");
              const response = await updateZweryfikowanePrzygotowaneDoWyslania(
                id,
                {
                  status: "Przygotowane",
                  Przygotowane: 1,
                  Zweryfikowane: 1,
                },
                "przywoz"
              );
              console.log(response.message);
              alert(response.message);
              setTimeout(() => {
                navigate("/user");
              }, 1500);
            }
          }
        } else {
          if (SAD !== undefined && Plik !== undefined) {
            if (
              allCheckboxesChecked &&
              allSelectFieldsFilled &&
              allTextFieldsFilled
            ) {
              const response = await updateStatus(id, {
                status: "Do weryfikacji",
                Przygotowane: 1,
              });
              console.log(response.message);
              alert(response.message);
              setTimeout(() => {
                navigate("/user");
              }, 1500);
            }
          }
        }
      }
    } catch (error) {
      console.error("Failed to update status:", error);
    }
  };

  const allDataSave = (data) => {
    const requiredTextFields = [9, 10, 16];
    const requiredSelectFields = [12, 24, 25, 28, 33, 34];
    const requiredCheckboxes = [
      1, 2, 3, 4, 5, 6, 7, 8, 11, 13, 14, 15, 17, 18, 19, 20, 21, 22, 23, 26,
      27, 29, 30, 31, 32,
    ];

    const allTextFieldsFilled = requiredTextFields.every(
      (fieldId) => data.textFields[fieldId]
    );
    const allSelectFieldsFilled = requiredSelectFields.every(
      (fieldId) => data.selectFields[fieldId]
    );
    const allCheckboxesChecked = requiredCheckboxes.every(
      (fieldId) =>
        data.checkboxesPreparation[fieldId] &&
        data.checkboxesPreparation[fieldId].verifying
    );

    return allTextFieldsFilled && allSelectFieldsFilled && allCheckboxesChecked;
  };

  const allInternalControlSave = (data) => {
    const requiredCheckboxes = [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34,
    ];

    const allCheckboxesHaveValue = requiredCheckboxes.every(
      (fieldId) =>
        data.checkboxesInternalControl[fieldId] &&
        data.checkboxesInternalControl[fieldId].internalControl === true
    );

    return allCheckboxesHaveValue;
  };

  const allVeryfyingDataSave = (data) => {
    const requiredCheckboxes = [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34,
    ];

    const allCheckboxesHaveValue = requiredCheckboxes.every(
      (fieldId) =>
        data.checkboxesVerification[fieldId] &&
        (data.checkboxesVerification[fieldId].good === true ||
          data.checkboxesVerification[fieldId].bad === true)
    );

    return allCheckboxesHaveValue;
  };

  const checkIfAllVerificationSet = (data) => {
    return Object.keys(data.checkboxesVerification).every(
      (key) =>
        data.checkboxesVerification[key].good !== false ||
        data.checkboxesVerification[key].bad !== false
    );
  };
  const fetchAndSetData = async () => {
    try {
      const fetchedData = await fetchData(id);
      const mappedData = {
        textFields: {
          9: fetchedData.field_9 || "",
          10: fetchedData.field_10 || "",
          16: fetchedData.field_16 || "",
        },
        selectFields: {
          12: fetchedData.field_12 || "",
          24: fetchedData.field_24 || "",
          25: fetchedData.field_25 || "",
          28: fetchedData.field_28 || "",
          33: fetchedData.field_33 || "",
          34: fetchedData.field_34 || "",
        },
        checkboxesPreparation: {
          1: { verifying: Boolean(fetchedData.field_1) },
          2: { verifying: Boolean(fetchedData.field_2) },
          3: { verifying: Boolean(fetchedData.field_3) },
          4: { verifying: Boolean(fetchedData.field_4) },
          5: { verifying: Boolean(fetchedData.field_5) },
          6: { verifying: Boolean(fetchedData.field_6) },
          7: { verifying: Boolean(fetchedData.field_7) },
          8: { verifying: Boolean(fetchedData.field_8) },
          11: { verifying: Boolean(fetchedData.field_11) },
          13: { verifying: Boolean(fetchedData.field_13) },
          14: { verifying: Boolean(fetchedData.field_14) },
          15: { verifying: Boolean(fetchedData.field_15) },
          17: { verifying: Boolean(fetchedData.field_17) },
          18: { verifying: Boolean(fetchedData.field_18) },
          19: { verifying: Boolean(fetchedData.field_19) },
          20: { verifying: Boolean(fetchedData.field_20) },
          21: { verifying: Boolean(fetchedData.field_21) },
          22: { verifying: Boolean(fetchedData.field_22) },
          23: { verifying: Boolean(fetchedData.field_23) },
          26: { verifying: Boolean(fetchedData.field_26) },
          27: { verifying: Boolean(fetchedData.field_27) },
          29: { verifying: Boolean(fetchedData.field_29) },
          30: { verifying: Boolean(fetchedData.field_30) },
          31: { verifying: Boolean(fetchedData.field_31) },
          32: { verifying: Boolean(fetchedData.field_32) },
        },
        checkboxesVerification: {
          1: {
            good: fetchedData.weryfikacja_1 === 1,
            bad: fetchedData.weryfikacja_1 === 0,
          },
          2: {
            good: fetchedData.weryfikacja_2 === 1,
            bad: fetchedData.weryfikacja_2 === 0,
          },
          3: {
            good: fetchedData.weryfikacja_3 === 1,
            bad: fetchedData.weryfikacja_3 === 0,
          },
          4: {
            good: fetchedData.weryfikacja_4 === 1,
            bad: fetchedData.weryfikacja_4 === 0,
          },
          5: {
            good: fetchedData.weryfikacja_5 === 1,
            bad: fetchedData.weryfikacja_5 === 0,
          },
          6: {
            good: fetchedData.weryfikacja_6 === 1,
            bad: fetchedData.weryfikacja_6 === 0,
          },
          7: {
            good: fetchedData.weryfikacja_7 === 1,
            bad: fetchedData.weryfikacja_7 === 0,
          },
          8: {
            good: fetchedData.weryfikacja_8 === 1,
            bad: fetchedData.weryfikacja_8 === 0,
          },
          9: {
            good: fetchedData.weryfikacja_9 === 1,
            bad: fetchedData.weryfikacja_9 === 0,
          },
          10: {
            good: fetchedData.weryfikacja_10 === 1,
            bad: fetchedData.weryfikacja_10 === 0,
          },
          11: {
            good: fetchedData.weryfikacja_11 === 1,
            bad: fetchedData.weryfikacja_11 === 0,
          },
          12: {
            good: fetchedData.weryfikacja_12 === 1,
            bad: fetchedData.weryfikacja_12 === 0,
          },
          13: {
            good: fetchedData.weryfikacja_13 === 1,
            bad: fetchedData.weryfikacja_13 === 0,
          },
          14: {
            good: fetchedData.weryfikacja_14 === 1,
            bad: fetchedData.weryfikacja_14 === 0,
          },
          15: {
            good: fetchedData.weryfikacja_15 === 1,
            bad: fetchedData.weryfikacja_15 === 0,
          },
          16: {
            good: fetchedData.weryfikacja_16 === 1,
            bad: fetchedData.weryfikacja_16 === 0,
          },
          17: {
            good: fetchedData.weryfikacja_17 === 1,
            bad: fetchedData.weryfikacja_17 === 0,
          },
          18: {
            good: fetchedData.weryfikacja_18 === 1,
            bad: fetchedData.weryfikacja_18 === 0,
          },
          19: {
            good: fetchedData.weryfikacja_19 === 1,
            bad: fetchedData.weryfikacja_19 === 0,
          },
          20: {
            good: fetchedData.weryfikacja_20 === 1,
            bad: fetchedData.weryfikacja_20 === 0,
          },
          21: {
            good: fetchedData.weryfikacja_21 === 1,
            bad: fetchedData.weryfikacja_21 === 0,
          },
          22: {
            good: fetchedData.weryfikacja_22 === 1,
            bad: fetchedData.weryfikacja_22 === 0,
          },
          23: {
            good: fetchedData.weryfikacja_23 === 1,
            bad: fetchedData.weryfikacja_23 === 0,
          },
          24: {
            good: fetchedData.weryfikacja_24 === 1,
            bad: fetchedData.weryfikacja_24 === 0,
          },
          25: {
            good: fetchedData.weryfikacja_25 === 1,
            bad: fetchedData.weryfikacja_25 === 0,
          },
          26: {
            good: fetchedData.weryfikacja_26 === 1,
            bad: fetchedData.weryfikacja_26 === 0,
          },
          27: {
            good: fetchedData.weryfikacja_27 === 1,
            bad: fetchedData.weryfikacja_27 === 0,
          },
          28: {
            good: fetchedData.weryfikacja_28 === 1,
            bad: fetchedData.weryfikacja_28 === 0,
          },
          29: {
            good: fetchedData.weryfikacja_29 === 1,
            bad: fetchedData.weryfikacja_29 === 0,
          },
          30: {
            good: fetchedData.weryfikacja_30 === 1,
            bad: fetchedData.weryfikacja_30 === 0,
          },
          31: {
            good: fetchedData.weryfikacja_31 === 1,
            bad: fetchedData.weryfikacja_31 === 0,
          },
          32: {
            good: fetchedData.weryfikacja_32 === 1,
            bad: fetchedData.weryfikacja_32 === 0,
          },
          33: {
            good: fetchedData.weryfikacja_33 === 1,
            bad: fetchedData.weryfikacja_33 === 0,
          },
          34: {
            good: fetchedData.weryfikacja_34 === 1,
            bad: fetchedData.weryfikacja_34 === 0,
          },
        },
        checkboxesInternalControl: {
          1: { internalControl: Boolean(fetchedData.internal_control_1) },
          2: { internalControl: Boolean(fetchedData.internal_control_2) },
          3: { internalControl: Boolean(fetchedData.internal_control_3) },
          4: { internalControl: Boolean(fetchedData.internal_control_4) },
          5: { internalControl: Boolean(fetchedData.internal_control_5) },
          6: { internalControl: Boolean(fetchedData.internal_control_6) },
          7: { internalControl: Boolean(fetchedData.internal_control_7) },
          8: { internalControl: Boolean(fetchedData.internal_control_8) },
          9: { internalControl: Boolean(fetchedData.internal_control_9) },
          10: { internalControl: Boolean(fetchedData.internal_control_10) },
          11: { internalControl: Boolean(fetchedData.internal_control_11) },
          12: { internalControl: Boolean(fetchedData.internal_control_12) },
          13: { internalControl: Boolean(fetchedData.internal_control_13) },
          14: { internalControl: Boolean(fetchedData.internal_control_14) },
          15: { internalControl: Boolean(fetchedData.internal_control_15) },
          16: { internalControl: Boolean(fetchedData.internal_control_16) },
          17: { internalControl: Boolean(fetchedData.internal_control_17) },
          18: { internalControl: Boolean(fetchedData.internal_control_18) },
          19: { internalControl: Boolean(fetchedData.internal_control_19) },
          20: { internalControl: Boolean(fetchedData.internal_control_20) },
          21: { internalControl: Boolean(fetchedData.internal_control_21) },
          22: { internalControl: Boolean(fetchedData.internal_control_22) },
          23: { internalControl: Boolean(fetchedData.internal_control_23) },
          24: { internalControl: Boolean(fetchedData.internal_control_24) },
          25: { internalControl: Boolean(fetchedData.internal_control_25) },
          26: { internalControl: Boolean(fetchedData.internal_control_26) },
          27: { internalControl: Boolean(fetchedData.internal_control_27) },
          28: { internalControl: Boolean(fetchedData.internal_control_28) },
          29: { internalControl: Boolean(fetchedData.internal_control_29) },
          30: { internalControl: Boolean(fetchedData.internal_control_30) },
          31: { internalControl: Boolean(fetchedData.internal_control_31) },
          32: { internalControl: Boolean(fetchedData.internal_control_32) },
          33: { internalControl: Boolean(fetchedData.internal_control_33) },
          34: { internalControl: Boolean(fetchedData.internal_control_34) },
        },
        nrRef: fetchedData.custom_Nr_Ref,
        osobaPrzygotowujaca: fetchedData.OsobaPrzygotowujacaOdprawe,
        osobaWeryfikujaca: fetchedData.OsobaWeryfikujacaOdprawe,
        comment: fetchedData.comment || "", // Pobierz komentarz z danych
        creationDate: fetchedData.created_at,
        lastUpdateDate: fetchedData.updated_at,
      };

      setData(mappedData);
      setIsAllDataSaved(allDataSave(mappedData));
      setIsAllInternalControleSaved(allInternalControlSave(mappedData));
      setAllVeryfyingDataFilled(allVeryfyingDataSave(data));
      setAllVerificationSet(checkIfAllVerificationSet(mappedData));
      verifyAndSubmit(mappedData, status, id, username);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAndSetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, status]);

  const formatDate = (dateTimeString) => {
    const originalDateTime = moment(dateTimeString, "YYYY-MM-DDTHH:mm:ss.SSSZ");

    return originalDateTime.format("DD-MM-YYYY, HH:mm");
  };

  const handleInputChange = (id, field, value) => {
    setData((prevData) => {
      const newData = {
        ...prevData,
        [field]: { ...prevData[field], [id]: value },
      };
      return newData;
    });
  };

  const handleVerificationChange = (id, type) => {
    setData((prevData) => {
      const newVerification = { good: false, bad: false };
      newVerification[type] = true;
      const newData = {
        ...prevData,
        checkboxesVerification: {
          ...prevData.checkboxesVerification,
          [id]: newVerification,
        },
      };
      setAllVerificationSet(checkIfAllVerificationSet(newData));
      return newData;
    });
  };

  const handleInternalControlChange = (id, checked) => {
    setData((prevData) => {
      const newInternalControl = {
        ...prevData.checkboxesInternalControl,
        [id]: { internalControl: checked },
      };
      return { ...prevData, checkboxesInternalControl: newInternalControl };
    });
  };

  const handleCommentChange = (e) => {
    setData((prevData) => ({
      ...prevData,
      comment: e.target.value,
    }));
  };

  const handleCommentChangeInternalControl = (e) => {
    setData((prevData) => ({
      ...prevData,
      comment: e.target.value,
    }));
  };

  const rows = [
    {
      id: 1,
      check: "1. Korespondencja umieszczona w folderze wskazanym w systemie",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 2,
      check: "2. Kontrahent otrzymał mail zawierający ticket",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 3,
      check: "3. Przygotowano plik do zaczytania do HUZAR",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 4,
      check: "4. Poprawny numer referencyjny z systemu wpisany w SAD",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 5,
      check: "5. Dane nadawcy",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 6,
      check: "6. Dane odbiorcy",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 7,
      check: "7. Rodzaj przedstawicielstwa",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 8,
      check: "8. Kod kraju wysyłki",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 9,
      check: "9. Wpisz urząd wprowadzenia",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 10,
      check: "10. Wpisz numery środka transportu",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 11,
      check: "11. Kraj przynależności środka transportu",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 12,
      check: "12. Wybierz INCOTERMS",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 13,
      check: "13. Waluta i kwota na fakturze",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 14,
      check: "14. Koszty transportu",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 15,
      check: "15. Rodzaj transakcji",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 16,
      check: "16. Wpisz lokalizację towaru",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 17,
      check: "17. Opis towaru i tłumaczenie",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 18,
      check: "18. Masa brutto",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 19,
      check: "19. Masa netto",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 20,
      check: "20. Liczba opakowań",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 21,
      check: "21. Czy zastosowano właściwą procedurę celną",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 22,
      check: "22. Taryfikacja towaru",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 23,
      check: "23. Kraj pochodzenia",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 24,
      check:
        "24. Wybierz czy kraje transakcji objęte są embargiem lub sankcjami",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 25,
      check: "25. Wybierz numer świadectwa pochodzenia",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 26,
      check:
        "26. Czy zastosowanie lub niezastosowanie preferencji zostało właściwie zadeklarowane",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 27,
      check: "27. Numer i typ faktury",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 28,
      check: "28. Wybierz numer świadectwa przewozowego/pochodzenia",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 29,
      check: "29. Numer listy przewozowego",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 30,
      check: "30. Stawka cła",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 31,
      check: "31. Stawka VAT",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 32,
      check: "32. Metoda płatności",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 33,
      check:
        "33. Wybierz czy wymagane jest zabezpieczenie na procedurę czasowego składowania",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 34,
      check: "34. Wybierz należności-celno podatkowe / rodzaj zabezpieczenia",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
  ];

  const saveData = async () => {
    const payload = {
      textFields: data.textFields,
      selectFields: data.selectFields,
      checkboxes: data.checkboxesPreparation,
      nrRef: nrRef,
      osobaPrzygotowujaca: osobaPrzygotowujaca,
      osobaWeryfikujaca: osobaWeryfikujaca,
      comment: data.comment,
    };
    console.log(payload);

    try {
      const response = await saveOrUpdateData(id, payload);

      console.log(response.message);
      alert(response.message);
      await fetchAndSetData();
      await verifyAndSubmit(data, status, id, username);

      setIsAllDataSaved(allDataSave(data));
      window.location.reload();
    } catch (error) {
      console.error("Failed to save or update data:", error);
      alert("Wystąpił błąd podczas zapisywania danych!");
    }
    if (isAllDataSaved) {
      setTimeout(() => {
        navigate("/user");
      }, 1500);
    }
  };

  const saveInternalControlData = async () => {
    const payload = {
      checkboxesInternalControl: data.checkboxesInternalControl,
      nrRef: nrRef,
    };

    console.log(payload);

    try {
      const response = await saveOrUpdateData(id, payload);
      console.log(response.message);

      if (data.comment) {
        const commentPayload = {
          comment: data.comment,
          username: username,
        };
        await saveComment(id, commentPayload);
        console.log("Comment saved successfully");
      }

      alert(response.message);
      window.location.reload();
    } catch (error) {
      console.error("Failed to save internal control data or comment:", error);
      alert(
        "Wystąpił błąd podczas zapisywania danych kontroli wewnętrznej lub komentarza!"
      );
    }
  };

  const saveVerifyingData = async () => {
    if (status === "Do wysłania") {
      console.log("Status is already 'Do wysłania', no action taken.");
      return;
    }

    const allCheckboxesHaveValue = Object.values(
      data.checkboxesVerification
    ).every((checkbox) => checkbox.good === true || checkbox.bad === true);

    if (!allCheckboxesHaveValue) {
      const payload = {
        checkboxes: data.checkboxesVerification,
        nrRef: nrRef,
        osobaWeryfikujaca: osobaWeryfikujaca,
        comment: data.comment,
      };

      try {
        const response = await saveVerifyingData1(id, payload);
        console.log(response.message);
        alert(response.message);
      } catch (error) {
        console.error("Failed to save verifying data:", error);
        alert("Wystąpił błąd podczas zapisywania danych weryfikujących!");
      }
      return;
    }

    const allCheckboxesVerified = Object.values(
      data.checkboxesVerification
    ).every((checkbox) => checkbox.good === true);

    console.log(`AllCheckboxesVerified ${allCheckboxesVerified}`);

    if (allCheckboxesVerified) {
      const payload = {
        checkboxes: data.checkboxesVerification,
        nrRef: nrRef,
        osobaWeryfikujaca: osobaWeryfikujaca,
        comment: data.comment,
      };

      try {
        const response = await saveVerifyingData1(id, payload);
        console.log(response.message);
        alert(response.message);

        const statusPayload = {
          status: "Do wysłania",
          Zweryfikowane: 1,
        };

        const statusResponse = await updateStatusDoWyslania(
          id,
          statusPayload,
          "przywoz"
        );
        console.log("Response from server:", statusResponse.message);
        setTimeout(() => {
          navigate("/user");
        }, 1500);
      } catch (error) {
        console.error("Failed to save verifying data or update status:", error);
        alert(
          "Wystąpił błąd podczas zapisywania danych weryfikujących lub aktualizacji statusu!"
        );
      }
    } else {
      if (!data.comment || data.comment.length === 0) {
        alert(
          "Wystąpił błąd podczas zapisywania danych, komentarz nie może być pusty!"
        );
        return;
      }

      try {
        const payload = {
          checkboxes: data.checkboxesVerification,
          nrRef: nrRef,
          osobaWeryfikujaca: osobaWeryfikujaca,
          comment: data.comment,
        };

        const response = await saveVerifyingData1(id, payload);
        console.log(response.message);
        alert(response.message);

        const commentPayload = {
          comment: data.comment,
          username: osobaWeryfikujaca,
        };

        const commentResponse = await saveComment(id, commentPayload);
        console.log(commentResponse.message);

        const statusPayload = {
          status: "Do poprawy",
          Zweryfikowane: 0,
        };

        const statusResponse = await updateStatusDoPoprawy(
          id,
          statusPayload,
          "przywoz"
        );
        console.log("Response from server:", statusResponse.message);

        const checkboxResponse = await saveCheckbox(id, { zglosBladBox: 1 });
        console.log(checkboxResponse.message);
        setTimeout(() => {
          navigate("/user");
        }, 1500);
      } catch (error) {
        console.error(
          "Failed to save verifying data, comment, or update status:",
          error
        );
        alert(
          "Wystąpił błąd podczas zapisywania danych weryfikujących, komentarza lub aktualizacji statusu!"
        );
      }
    }
  };

  if (loading) {
    return (
      <div className={classes.loader}>
        <CircularProgress />
        <div>Pobieranie danych...</div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            Nr. Ref: {nrRef}, Rodzaj odprawy: {rodzajOdprawy}, Status: {status},
            Osoba przygotowująca: {osobaPrzygotowujaca}, Osoba weryfikująca:{" "}
            {osobaWeryfikujaca}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{ paper: classes.drawerPaper }}
      >
        <Toolbar />
        <List>
          <ListItem
            button
            onClick={() =>
              navigate(
                `/detail/${encodeURIComponent(nrRef)}/${encodeURIComponent(id)}`
              )
            }
          >
            <ListItemIcon>
              <ArrowBackIcon />
            </ListItemIcon>
            <ListItemText primary="Powrót do tabeli" />
          </ListItem>
          <ListItem button onClick={() => navigate("/login")}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Wyloguj" />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeadCell}>Sprawdź</TableCell>
                <TableCell className={classes.tableHeadCell}>
                  Osoba przygotowująca
                </TableCell>
                <TableCell className={classes.tableHeadCell}>
                  Osoba weryfikująca
                </TableCell>
                <TableCell className={classes.tableHeadCell}>
                  Kontrola wew.
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell className={classes.checkCell}>
                    {row.check}
                  </TableCell>
                  <TableCell>
                    {[9, 10, 16].includes(row.id) ? (
                      <TextField
                        className={classes.inputField}
                        value={data.textFields[row.id] || ""}
                        onChange={(e) =>
                          handleInputChange(
                            row.id,
                            "textFields",
                            e.target.value
                          )
                        }
                        variant="outlined"
                        size="small"
                        disabled={
                          username !== osobaPrzygotowujaca || isAllDataSaved
                        }
                      />
                    ) : [12, 24, 25, 28, 33, 34].includes(row.id) ? (
                      <Select
                        className={classes.inputField}
                        value={data.selectFields[row.id] || ""}
                        onChange={(e) =>
                          handleInputChange(
                            row.id,
                            "selectFields",
                            e.target.value
                          )
                        }
                        displayEmpty
                        disabled={
                          username !== osobaPrzygotowujaca || isAllDataSaved
                        }
                      >
                        {selectOptions[row.id].map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <Checkbox
                        checked={
                          data.checkboxesPreparation[row.id]?.verifying || false
                        }
                        onChange={(e) =>
                          handleInputChange(row.id, "checkboxesPreparation", {
                            ...data.checkboxesPreparation[row.id],
                            verifying: e.target.checked,
                          })
                        }
                        color="primary"
                        disabled={
                          username !== osobaPrzygotowujaca || isAllDataSaved
                        }
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <div>
                      <Checkbox
                        checked={
                          data.checkboxesVerification[row.id]?.good || false
                        }
                        onChange={() =>
                          handleVerificationChange(row.id, "good")
                        }
                        color="primary"
                        disabled={
                          username !== osobaWeryfikujaca ||
                          allVeryfyingDataFilled ||
                          allVerificationSet
                        } // Dodanie allVerificationSet do warunku disabled
                      />
                      <Checkbox
                        checked={
                          data.checkboxesVerification[row.id]?.bad || false
                        }
                        onChange={() => handleVerificationChange(row.id, "bad")}
                        color="secondary"
                        disabled={
                          username !== osobaWeryfikujaca || allVerificationSet
                        } // Dodanie allVerificationSet do warunku disabled
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={
                        data.checkboxesInternalControl[row.id]
                          ?.internalControl || false
                      }
                      onChange={(e) =>
                        handleInternalControlChange(row.id, e.target.checked)
                      }
                      color="primary"
                      disabled={
                        !allowedUsers.includes(username) ||
                        isAllInternalControleSaved
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {username === osobaWeryfikujaca && (
          <>
            <TextField
              label="Napisz co było nie tak"
              multiline
              rows={4}
              value={data.comment}
              onChange={handleCommentChange}
              variant="outlined"
              fullWidth
              margin="normal"
              disabled={allVeryfyingDataFilled}
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={saveVerifyingData}
              style={{ marginTop: "20px" }}
              disabled={allVeryfyingDataFilled}
            >
              Zapisz dane weryfikacyjne
            </Button>
          </>
        )}
        {username === osobaPrzygotowujaca && (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={saveData}
              style={{ marginTop: "20px" }}
              disabled={isAllDataSaved || allVerificationSet}
            >
              Zapisz dane
            </Button>
          </>
        )}
        {allowedUsers.includes(username) && (
          <>
            <TextField
              label="Napisz jeśli chcesz komentarz do kontroli wewnętrznej"
              multiline
              rows={4}
              value={data.comment}
              onChange={handleCommentChangeInternalControl}
              variant="outlined"
              fullWidth
              margin="normal"
              disabled={allVeryfyingDataFilled}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={saveInternalControlData}
              style={{ marginTop: "20px" }}
              disabled={
                !allowedUsers.includes(username) || isAllInternalControleSaved
              }
            >
              Zapisz dane kontroli wewnętrznej
            </Button>
          </>
        )}

        <div style={{ marginTop: "20px" }}>
          {formatDate(data.creationDate) !== "Invalid date" && (
            <Typography variant="body1">
              Data utworzenia listy: {formatDate(data.creationDate)}
            </Typography>
          )}
          {formatDate(data.lastUpdateDate) !== "Invalid date" && (
            <Typography variant="body1">
              Data ostatniej aktualizacji: {formatDate(data.lastUpdateDate)}
            </Typography>
          )}
        </div>
      </main>
    </div>
  );
};
export default PrzywozZwykly;
