import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  CssBaseline,
  AppBar,
  Typography,
  Collapse,
  IconButton,
  Button,
  TablePagination,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import {
  ExitToApp as ExitToAppIcon,
  ArrowBack as ArrowBackIcon,
  ExpandLess,
  ExpandMore,
} from "@material-ui/icons";
import ListIcon from "@mui/icons-material/List";
import { fetchWnioskiWithHistory, deleteWniosek } from "./RejestrFunctions";
import moment from "moment";
import { jwtDecode } from "jwt-decode";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  tableContainer: {
    maxHeight: "calc(100vh - 64px - 16px)",
    maxWidth: "88vw",
    overflow: "auto",
  },
  tableHeadCell: {
    fontWeight: "bold",
    fontSize: "1rem",
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: theme.zIndex.appBar,
  },
  filterContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    minWidth: 150,
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "column", // Ustawiamy kolumnę dla ułożenia loadera i tekstu
    justifyContent: "center", // Wyśrodkowanie w poziomie
    alignItems: "center", // Wyśrodkowanie w pionie
    height: "100vh", // Pełna wysokość ekranu
  },
}));

const LightGrayLedIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="10" fill="#CCCCCC" />
    <circle
      cx="12"
      cy="12"
      r="7"
      fill="none"
      stroke="#CCCCCC"
      strokeWidth="3"
    />
  </svg>
);
const RedLedIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="10" fill="#FF0000" />
  </svg>
);
const YellowLedIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="10" fill="#FFFF00" />
  </svg>
);

const columns = [
  { id: "history", label: "Historia", minWidth: 50 },
  { id: "edit", label: "Edit", minWidth: 50 },
  //{ id: "id", label: "Nr. ID", minWidth: 170 },
  { id: "unique_number", label: "Nr. wniosku", minWidth: 170 },
  { id: "status", label: "Status", minWidth: 170 },
  { id: "data_wpisania", label: "Data wpisania", minWidth: 170 },
  { id: "powod_wpisania", label: "Powód wpisania", minWidth: 170 },
  { id: "data_SAD", label: "Data SAD-u", minWidth: 170 },
  { id: "nr_MRN", label: "Nr ewidencyjny MRN", minWidth: 170 },
  { id: "nr_akt", label: "Nr akt", minWidth: 170 },
  { id: "nadawca_odbiorca", label: "Nadawca", minWidth: 170 },
  { id: "odbiorca", label: "Odbiorca", minWidth: 170 },
  { id: "podpisany", label: "Podpisany", minWidth: 130 },
  { id: "rodzaj_SAD", label: "Rodzaj SAD-u", minWidth: 170 },
  { id: "procedura", label: "Procedura", minWidth: 170 },
  { id: "platnik", label: "Płatnik", minWidth: 170 },
  { id: "data_wyslania", label: "Data wysłania do UC", minWidth: 150 },
  { id: "data_odebrania", label: "Data odebrania decyzji z UC", minWidth: 170 },
  { id: "delete_button", label: "Usuń", minWidth: 100 },
];

const RejestrWnioskow = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [openRows, setOpenRows] = useState({});
  const [wnioski, setWnioski] = useState([]);
  const [filteredWnioski, setFilteredWnioski] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(20); // Default number of rows per page
  const [page, setPage] = useState(0); // Current page
  const [filters, setFilters] = useState({
    nr_MRN: "",
    nr_akt: "",
    data_wpisania: "",
    status: "",
    podpisany: "",
    procedura: "",
    platnik: "",
    data_wyslania: "",
    data_odebrania: "",
    data_wyslania_start: "",
    data_wyslania_end: "",
    data_odebrania_start: "",
    data_odebrania_end: "",
    searchTerm: "", // Nowa właściwość
  });

  const token = localStorage.getItem("token");
  let role;
  if (token) {
    const decoded = jwtDecode(token);
    role = decoded.role;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchWnioskiWithHistory();
        const fetchedData = response.data;

        // Pobierz filtry z localStorage
        const storedFilters = JSON.parse(
          localStorage.getItem("filtersRejestr")
        );

        // Jeśli są zapisane filtry, stosujemy je do pobranych danych
        if (storedFilters) {
          setFilters(storedFilters);
          const filteredData = filterData(fetchedData, storedFilters);
          setFilteredWnioski(filteredData);
        } else {
          // Jeśli nie ma zapisanych filtrów, pokaż wszystkie dane
          setFilteredWnioski(fetchedData);
        }

        setWnioski(fetchedData); // Zawsze zapisujemy pobrane dane
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const filterData = (data, filters) => {
    return data.filter((row) => {
      const formattedDataWpisania = moment(row.data_wpisania).format(
        "YYYY-MM-DD"
      );
      const formattedDataWyslania = moment(row.data_wyslania).format(
        "YYYY-MM-DD"
      );
      const formattedDataOdebrania = moment(row.data_odebrania).format(
        "YYYY-MM-DD"
      );
      // Przeszukuj wszystkie kolumny
      const matchesSearchTerm = Object.values(row).some(
        (value) =>
          value !== null &&
          value !== undefined && // Sprawdzenie czy wartość jest null lub undefined
          value
            .toString()
            .toLowerCase()
            .includes(filters.searchTerm.toLowerCase())
      );

      return (
        matchesSearchTerm &&
        (filters.nr_MRN === "" || row.nr_MRN.includes(filters.nr_MRN)) &&
        (filters.nr_akt === "" || row.nr_akt.includes(filters.nr_akt)) &&
        (filters.data_wpisania === "" ||
          formattedDataWpisania ===
            moment(filters.data_wpisania).format("YYYY-MM-DD")) &&
        (filters.status === "" || row.status === filters.status) &&
        (filters.podpisany === "" || row.podpisany === filters.podpisany) &&
        (filters.procedura === "" || row.procedura === filters.procedura) &&
        (filters.platnik === "" || row.platnik === filters.platnik) &&
        (filters.data_wyslania_start === "" ||
          moment(formattedDataWyslania).isSameOrAfter(
            filters.data_wyslania_start
          )) &&
        (filters.data_wyslania_end === "" ||
          moment(formattedDataWyslania).isSameOrBefore(
            filters.data_wyslania_end
          )) &&
        (filters.data_odebrania_start === "" ||
          moment(formattedDataOdebrania).isSameOrAfter(
            filters.data_odebrania_start
          )) &&
        (filters.data_odebrania_end === "" ||
          moment(formattedDataOdebrania).isSameOrBefore(
            filters.data_odebrania_end
          ))
      );
    });
  };

  const handleEditClick = (id) => {
    navigate(`/rejestrWnioskow/${id}`);
  };

  const handleRowClick = (id) => {
    setOpenRows((prevOpenRows) => ({
      ...prevOpenRows,
      [id]: !prevOpenRows[id],
    }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleSearch = () => {
    const filteredData = wnioski.filter((row) => {
      const formattedDataWpisania = moment(row.data_wpisania).format(
        "YYYY-MM-DD"
      );
      const formattedFilterDataWpisania = moment(
        filters.data_wpisania,
        "YYYY-MM-DD"
      ).format("YYYY-MM-DD");

      const formattedDataWyslania = moment(row.data_wyslania).format(
        "YYYY-MM-DD"
      );

      const formattedDataOdebrania = moment(row.data_odebrania).format(
        "YYYY-MM-DD"
      );
      // Przeszukuj wszystkie kolumny
      const matchesSearchTerm = Object.values(row).some(
        (value) =>
          value !== null &&
          value !== undefined && // Sprawdzenie czy wartość jest null lub undefined
          value
            .toString()
            .toLowerCase()
            .includes(filters.searchTerm.toLowerCase())
      );

      return (
        matchesSearchTerm &&
        (filters.nr_MRN === "" || row.nr_MRN.includes(filters.nr_MRN)) &&
        (filters.nr_akt === "" || row.nr_akt.includes(filters.nr_akt)) &&
        (filters.data_wpisania === "" ||
          formattedDataWpisania === formattedFilterDataWpisania) &&
        (filters.status === "" || row.status === filters.status) &&
        (filters.podpisany === "" || row.podpisany === filters.podpisany) &&
        (filters.procedura === "" || row.procedura === filters.procedura) &&
        (filters.platnik === "" || row.platnik === filters.platnik) &&
        (filters.data_wyslania_start === "" ||
          moment(formattedDataWyslania).isSameOrAfter(
            filters.data_wyslania_start
          )) &&
        (filters.data_wyslania_end === "" ||
          moment(formattedDataWyslania).isSameOrBefore(
            filters.data_wyslania_end
          )) &&
        (filters.data_odebrania_start === "" ||
          moment(formattedDataOdebrania).isSameOrAfter(
            filters.data_odebrania_start
          )) &&
        (filters.data_odebrania_end === "" ||
          moment(formattedDataOdebrania).isSameOrBefore(
            filters.data_odebrania_end
          ))
      );
    });
    setFilteredWnioski(filteredData);
    localStorage.setItem("filtersRejestr", JSON.stringify(filters));
  };

  const handleClearFilters = () => {
    setFilters({
      nr_MRN: "",
      nr_akt: "",
      data_wpisania: "",
      status: "",
      podpisany: "",
      procedura: "",
      platnik: "",
      data_wyslania: "",
      data_odebrania: "",
      data_wyslania_start: "",
      data_wyslania_end: "",
      data_odebrania_start: "",
      data_odebrania_end: "",
      searchTerm: "",
    });
    setFilteredWnioski(wnioski); // Resetuj filtrowane dane do pełnej listy
    localStorage.removeItem("filtersRejestr");
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "W toku":
        return <YellowLedIcon />;
      case "Wysłany":
        return <RedLedIcon />;
      case "Zakończony":
        return <LightGrayLedIcon />;
      default:
        return null;
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await deleteWniosek(id); // Wywołaj funkcję usuwającą

      if (response.status === 200) {
        // Po pomyślnym usunięciu zaktualizuj stan, usuwając wiersz
        setFilteredWnioski((prevWnioski) =>
          prevWnioski.filter((wniosek) => wniosek.id !== id)
        );
        // Powiadomienie o sukcesie
        toast.success("Wniosek został pomyślnie usunięty!");
      } else {
        // Powiadomienie o niepowodzeniu (status różny od 200)
        toast.error(`Nie udało się usunąć wniosku. Status: ${response.status}`);
      }
    } catch (error) {
      console.error("Błąd podczas usuwania wniosku:", error);
      // Powiadomienie o błędzie
      toast.error("Błąd podczas usuwania wniosku.");
    }
  };

  if (loading)
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress color="secondary" />
        <Typography variant="body1">Loading...</Typography>
      </div>
    );

  if (error) return <div>{`Error: ${error}`}</div>;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            Rejestr Wniosków
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <List>
          <ListItem button onClick={() => navigate("/user")}>
            <ListItemIcon>
              <ArrowBackIcon />
            </ListItemIcon>
            <ListItemText primary="Powrót do tabeli" />
          </ListItem>
          {role === "admin" && (
            <>
              <ListItem button onClick={() => navigate("/nowyWniosek")}>
                <ListItemIcon>
                  <ListIcon />
                </ListItemIcon>
                <ListItemText primary="Dodaj nowy wniosek" />
              </ListItem>
              <ListItem button onClick={() => navigate("/rejestrLists")}>
                <ListItemIcon>
                  <ListIcon />
                </ListItemIcon>
                <ListItemText primary="Listy" />
              </ListItem>
            </>
          )}
          <ListItem button onClick={() => navigate("/login")}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Wyloguj" />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        <div className={classes.filterContainer}>
          <TextField
            label="Wyszukaj"
            name="searchTerm"
            value={filters.searchTerm}
            onChange={handleFilterChange}
          />
          <TextField
            label="Nr. MRN"
            name="nr_MRN"
            value={filters.nr_MRN}
            onChange={handleFilterChange}
          />
          <TextField
            label="Nr. akt"
            name="nr_akt"
            value={filters.nr_akt}
            onChange={handleFilterChange}
          />
          <TextField
            label="Data wpisania"
            name="data_wpisania"
            type="date"
            value={filters.data_wpisania}
            onChange={handleFilterChange}
            InputLabelProps={{
              shrink: true,
              style: { fontSize: "1.2rem" },
            }}
            InputProps={{
              style: { paddingTop: 20 }, // Dodaj padding
            }}
          />
          <TextField
            label="Data wysłania do UC od"
            name="data_wyslania_start"
            type="date"
            value={filters.data_wyslania_start}
            onChange={handleFilterChange}
            InputLabelProps={{
              shrink: true,
              style: { fontSize: "1.2rem" },
            }}
            InputProps={{
              style: { paddingTop: 20 }, // Dodaj padding
            }}
          />
          <TextField
            label="Data wysłania do UC do"
            name="data_wyslania_end"
            type="date"
            value={filters.data_wyslania_end}
            onChange={handleFilterChange}
            InputLabelProps={{
              shrink: true,
              style: { fontSize: "1.2rem" },
            }}
            InputProps={{
              style: { paddingTop: 20 }, // Dodaj padding
            }}
          />
          <TextField
            label="Data odebrania dokumentów z UC od"
            name="data_odebrania_start"
            type="date"
            value={filters.data_odebrania_start}
            onChange={handleFilterChange}
            InputLabelProps={{
              shrink: true,
              style: { fontSize: "1.2rem" },
            }}
            InputProps={{
              style: { paddingTop: 20 }, // Dodaj padding
            }}
          />
          <TextField
            label="Data odebrania dokumentów z UC do"
            name="data_odebrania_end"
            type="date"
            value={filters.data_odebrania_end}
            onChange={handleFilterChange}
            InputLabelProps={{
              shrink: true,
              style: { fontSize: "1.2rem" },
            }}
            InputProps={{
              style: { paddingTop: 20 }, // Dodaj padding
            }}
          />

          <FormControl className={classes.formControl}>
            <InputLabel>Status</InputLabel>
            <Select
              name="status"
              value={filters.status}
              onChange={handleFilterChange}
            >
              <MenuItem value="">Wszystkie</MenuItem>
              <MenuItem value="W toku">W toku</MenuItem>
              <MenuItem value="Do weryfikacji">Do weryfikacji</MenuItem>
              <MenuItem value="Do poprawy">Do poprawy</MenuItem>
              <MenuItem value="Do wysłania">Do wysłania</MenuItem>
              <MenuItem value="Wysłany">Wysłany</MenuItem>
              <MenuItem value="Do poprawy na wniosek UC">
                Do poprawy na wniosek UC
              </MenuItem>
              <MenuItem value="Wycofany">Wycofany</MenuItem>
              <MenuItem value="Zakończony">Zakończony</MenuItem>
            </Select>
          </FormControl>

          <FormControl className={classes.formControl}>
            <InputLabel>Podpisany</InputLabel>
            <Select
              name="podpisany"
              value={filters.podpisany}
              onChange={handleFilterChange}
            >
              <MenuItem value="">Wszystkie</MenuItem>
              <MenuItem value="BRUŚ BARBARA">BRUŚ BARBARA</MenuItem>
              <MenuItem value="CZERNIKOWSKA DOMINIKA">
                CZERNIKOWSKA DOMINIKA
              </MenuItem>
              <MenuItem value="DOBROWOLSKA-MITRĘGA ANNA">
                DOBROWOLSKA-MITRĘGA ANNA
              </MenuItem>
              <MenuItem value="MAZUR SABINA">MAZUR SABINA</MenuItem>
              <MenuItem value="MUNIK PIOTR">MUNIK PIOTR</MenuItem>
              <MenuItem value="NOWAK PRZEMYSŁAW">NOWAK PRZEMYSŁAW</MenuItem>
              <MenuItem value="REDUCHA KATARZYNA">REDUCHA KATARZYNA</MenuItem>
              <MenuItem value="WOJTAŚ MILENA">WOJTAŚ MILENA</MenuItem>
            </Select>
          </FormControl>

          <FormControl className={classes.formControl}>
            <InputLabel>Procedura</InputLabel>
            <Select
              name="procedura"
              value={filters.procedura}
              onChange={handleFilterChange}
            >
              <MenuItem value="">Wszystkie</MenuItem>
              <MenuItem value="1000">1000</MenuItem>
              <MenuItem value="4000">4000</MenuItem>
              <MenuItem value="4071">4071</MenuItem>
              <MenuItem value="4500">4500</MenuItem>
              <MenuItem value="6110">6110</MenuItem>
              <MenuItem value="6121">6121</MenuItem>
            </Select>
          </FormControl>

          <FormControl className={classes.formControl}>
            <InputLabel>Płatnik</InputLabel>
            <Select
              name="platnik"
              value={filters.platnik}
              onChange={handleFilterChange}
            >
              <MenuItem value="">Wszystkie</MenuItem>
              <MenuItem value="Klient">Klient</MenuItem>
              <MenuItem value="WEGA">WEGA</MenuItem>
            </Select>
          </FormControl>

          <Button variant="contained" color="primary" onClick={handleSearch}>
            Szukaj
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClearFilters}
          >
            Wyczyść filtry
          </Button>
        </div>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align="left"
                    style={{ minWidth: column.minWidth }}
                    className={classes.tableHeadCell}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredWnioski
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <React.Fragment key={row.id}>
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      {columns.map((column) => {
                        let value = row[column.id];

                        // Formatowanie daty
                        if (column.id === "data_SAD" && value) {
                          value = moment(value).format("DD-MM-YYYY");
                        } else if (column.id === "data_wyslania" && value) {
                          value = moment(value).format("DD-MM-YYYY");
                        } else if (column.id === "data_wpisania" && value) {
                          value = moment(value).format("DD-MM-YYYY, HH:mm");
                        } else if (column.id === "data_odebrania" && value) {
                          value = moment(value).format("DD-MM-YYYY");
                        }

                        return (
                          <TableCell key={column.id} align="left">
                            {column.id === "status" ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {getStatusIcon(row[column.id])}
                                <span style={{ marginLeft: 8 }}>{value}</span>
                              </div>
                            ) : column.id === "history" ? (
                              <IconButton
                                onClick={() => handleRowClick(row.id)}
                              >
                                {openRows[row.id] ? (
                                  <ExpandLess />
                                ) : (
                                  <ExpandMore />
                                )}
                              </IconButton>
                            ) : column.id === "edit" ? (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleEditClick(row.id)}
                              >
                                Edit
                              </Button>
                            ) : column.id === "delete_button" ? (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleDelete(row.id)}
                                disabled={role !== "admin"} // Wyłącz przycisk, jeśli użytkownik nie jest adminem
                              >
                                Usuń
                              </Button>
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          paddingBottom: 0,
                          paddingTop: 0,
                          paddingLeft: "16px",
                        }}
                        colSpan={columns.length}
                      >
                        <Collapse
                          in={openRows[row.id]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Typography
                            variant="body2"
                            gutterBottom
                            component="div"
                          >
                            <strong className={classes.historySection}>
                              Historia zmian we wniosku:
                            </strong>
                            <ul>
                              {row.history &&
                                row.history.map((change) => (
                                  <li key={change.id}>
                                    <span style={{ fontWeight: 800 }}>
                                      Pole:{" "}
                                    </span>
                                    {change.field},{" "}
                                    <span style={{ fontWeight: 800 }}>
                                      Stara wartość:{" "}
                                    </span>
                                    {change.old_value},
                                    <span style={{ fontWeight: 800 }}>
                                      Nowa wartość:{" "}
                                    </span>
                                    {change.new_value},
                                    <span style={{ fontWeight: 800 }}>
                                      Edytowane przez:{" "}
                                    </span>
                                    {change.username},
                                    <span style={{ fontWeight: 800 }}>
                                      Zmieniono dnia:{" "}
                                    </span>
                                    {moment(change.timestamp).format(
                                      "DD-MM-YYYY, HH:mm"
                                    )}
                                  </li>
                                ))}
                            </ul>
                            {/* Sekcja Komentarzy */}
                            <strong className={classes.commentsSection}>
                              Komentarze:
                            </strong>
                            <ul>
                              {row.comments &&
                                row.comments.map((comment) => (
                                  <li key={comment.id}>
                                    <span style={{ fontWeight: 800 }}>
                                      Użytkownik:{" "}
                                    </span>
                                    {comment.username},
                                    <span style={{ fontWeight: 800 }}>
                                      Komentarz:{" "}
                                    </span>
                                    {comment.comment},
                                    <span style={{ fontWeight: 800 }}>
                                      Data:{" "}
                                    </span>
                                    {moment(comment.created_at).format(
                                      "DD-MM-YYYY, HH:mm"
                                    )}
                                  </li>
                                ))}
                            </ul>
                          </Typography>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={filteredWnioski.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <ToastContainer />
      </main>
    </div>
  );
};

export default RejestrWnioskow;
